import { defineComponent, toRefs, reactive, computed, watch, nextTick, defineAsyncComponent } from "vue";
import Eldialog from "@/components/Eldialog/index.vue";
import { useMessage } from '@/hooks/web/useMessage';
import { useDebounce } from '@/hooks/core/useDebounce';
import { useStore } from "vuex";
export default defineComponent({
  emits: ["handleSuccess", "update:visible"],
  components: {
    Eldialog,
    OrganizeSelect: defineAsyncComponent(() => import("@/components/OrganizeSelect/index.vue")),
    Org: defineAsyncComponent(() => import("./Org.vue")),
    School: defineAsyncComponent(() => import("./School.vue"))
  },
  props: {
    schoolInfo: {
      type: Object,
      default: null
    },
    orgInfo: {
      type: Object,
      default: null
    },
    activeOrg: {
      type: Object,
      default: {
        orgPid: null,
        orgName: ''
      }
    },
    orgId: {
      type: Number,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      type: null,
      orgRef: null,
      schoolRef: null,
      loading: false
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit("update:visible", val);
      }
    });
    watch(() => refData.type, val => {
      nextTick(() => {
        setTimeout(() => {
          var _refData$name, _refData$name2;
          const name = !refData.type ? 'orgRef' : 'schoolRef';
          (_refData$name = refData[name]) === null || _refData$name === void 0 || _refData$name.init();
          (_refData$name2 = refData[name]) === null || _refData$name2 === void 0 || _refData$name2.handleAnyway();
          refData.loading = false;
        }, 600);
      });
    });
    watch(() => props.visible, val => {
      refData.type = val ? 0 : null;
      refData.loading = true;
    });
    //添加成功回调
    const handleConfirm = () => {
      const name = !refData.type ? 'orgRef' : 'schoolRef';
      refData[name].confirm();
    };
    //添加成功回调
    const handleSuccess = () => {
      emit("update:visible", false);
      emit('handleSuccess');
    };
    const [onConfirm] = useDebounce(handleConfirm, 300);
    return {
      ...toRefs(refData),
      show,
      handleSuccess,
      onConfirm
    };
  }
});